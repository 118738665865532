// src/components/Auth/SignInModal.js
import React from 'react';
import GoogleSignIn from './GoogleSignIn';
import AppleSignIn from './AppleSignIn';
import SignInForm from './SignInForm';
import '../../styles/SignInModal.css';

const SignInModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="sign-in-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Sign In to Your Account</h2>
        <div className="social-sign-in-buttons">
          <GoogleSignIn />
          <AppleSignIn />
        </div>
        <div className="divider">
          <span>or</span>
        </div>
        <SignInForm />
        <div className="forgot-password">
          <a href="/forgot-password">Forgot my password</a>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
