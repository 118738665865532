// src/components/Auth/AppleSignIn.js
import React from 'react';
import { FaApple } from 'react-icons/fa'; // Apple icon
import '../../styles/SocialSignInButton.css'; // CSS for social sign-in buttons

const AppleSignIn = () => {
  const handleLogin = () => {
    // Implement sign-in with Apple here
    // For now, this is just a placeholder
    alert('Sign in with Apple is not implemented yet.');
  };

  return (
    <button className="social-sign-in-button" onClick={handleLogin}>
      <FaApple className="icon" />
      Sign in with Apple
    </button>
  );
};

export default AppleSignIn;
