// src/components/Layout/MainLayout.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from './Sidebar'; // Adjust the import path if necessary
import '../../styles/App.css';
import useAuth from '../../hooks/useAuth'; // Import useAuth hook
import { useFirestore } from '../../contexts/FirestoreContext';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import '../../styles/material3-dark.css';
import SignInModal from '../Auth/SignInModal'; // Import SignInModal

const MainLayout = ({ children }) => {
  const { projects } = useFirestore();
  const { currentUser, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for sign-in modal
  const navigate = useNavigate();

  const addProject = async (newProject) => {
    await addDoc(collection(db, 'users', currentUser.uid, 'projects'), newProject);
  };

  const handleProjectSelect = (project) => {
    navigate(`/project/${project.id}`);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="main-layout">
      <div className="header">
        <button className="menu-button" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          ☰
        </button>
        <div className="search-bar">
          <input type="text" placeholder="Search..." />
        </div>
        <div className="user-info">
          {currentUser ? (
            <>
              <span>{currentUser.displayName}</span>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button className="login-button" onClick={() => setIsModalOpen(true)}>
                Sign In
              </button>
              {isModalOpen && <SignInModal onClose={() => setIsModalOpen(false)} />}
            </>
          )}
        </div>
      </div>
      <div className="main">
        <Sidebar
          isOpen={isSidebarOpen}
          projects={projects}
          addProject={addProject}
          onProjectSelect={handleProjectSelect}
        />
        <div className={`main-content ${isSidebarOpen ? 'with-sidebar' : 'collapsed'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
