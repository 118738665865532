// src/components/Tasks/TaskTreeGrid.jsx

import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Filter,
  Reorder,
  Edit,
  RowDD,
  Selection,
  Toolbar,
  ColumnChooser,
  Freeze,
  ContextMenu,
} from '@syncfusion/ej2-react-treegrid';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { FirestoreContext } from '../../contexts/FirestoreContext';
import useAuth from '../../hooks/useAuth';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  writeBatch,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';
import '../../styles/App.css';
import AddTask from './AddTask';

/**
 * TaskTreeGrid Component
 * 
 * Displays a TreeGrid of tasks filtered by projectId and optionally by groupId.
 * 
 * Props:
 * - selectedProjectId: ID of the selected project to filter tasks.
 * - allColumns: Array of column configurations for the TreeGrid.
 * - groupId: (Optional) ID of the group to filter tasks. If null, displays ungrouped tasks.
 * - gridId: Unique identifier for the TreeGrid instance.
 */
const TaskTreeGrid = ({ selectedProjectId, allColumns, groupId = null, gridId }) => {
  // Access Firestore instance from context
  const { firestore } = useContext(FirestoreContext);

  // Access current user from authentication hook
  const { currentUser } = useAuth();

  // State to store tasks fetched from Firestore
  const [tasks, setTasks] = useState([]);

  // State to manage loading status
  const [loading, setLoading] = useState(false);

  // State to manage the visibility of the AddTask modal
  const [isAddingTask, setIsAddingTask] = useState(false);

  // Ref to store old positions of tasks before dragging (for reordering)
  const draggedOldPositionsRef = useRef({});

  // Ref to prevent snapshot listener from interfering during updates
  const isUpdatingRef = useRef(false);

  // Toolbar options: Only include the Column Chooser
  const toolbarOptions = ['ColumnChooser'];

  // Define context menu items for the TreeGrid
  const contextMenuItems = [
    { text: 'Delete Task', target: '.e-content', id: 'deleteTask' },
  ];

  // Define boolean fields to exclude from single-click editing
  const booleanFields = [
    'completed',
    'hearted',
    'liked',
    'done',
    'is_rendered_as_separator',
  ];

  /**
   * useEffect Hook: Fetch tasks from Firestore based on selectedProjectId and groupId
   */
  useEffect(() => {
    const fetchTasks = () => {
      if (selectedProjectId && firestore && currentUser) {
        console.log(`[TaskTreeGrid] Fetching tasks for projectId: ${selectedProjectId}, groupId: ${groupId}`);
        setLoading(true);

        // Reference to the 'tasks' collection for the current user
        const tasksCollection = collection(
          firestore,
          'users',
          currentUser.uid,
          'tasks'
        );

        // Build Firestore query constraints
        const qConstraints = [where('project_id', '==', selectedProjectId)];
        if (groupId) {
          qConstraints.push(where('group_id', '==', groupId));
          console.log(`[TaskTreeGrid] Adding where clause for group_id: ${groupId}`);
        } else {
          // Firestore treats '== null' as matching documents where 'group_id' is null or does not exist
          qConstraints.push(where('group_id', '==', null));
          console.log(`[TaskTreeGrid] Adding where clause for group_id: null`);
        }

        // Create the Firestore query
        const q = query(tasksCollection, ...qConstraints);

        // Subscribe to the Firestore query
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            if (isUpdatingRef.current) {
              console.log('[TaskTreeGrid] Snapshot listener skipped due to ongoing update.');
              return;
            }

            const tasksData = [];
            querySnapshot.forEach((docSnap) => {
              const data = docSnap.data();
              tasksData.push({ id: docSnap.id, ...data });
            });

            console.log(`[TaskTreeGrid] Fetched ${tasksData.length} tasks:`, tasksData);

            // Initialize and sanitize tasks data
            const initializedTasks = tasksData.map((task, index) => {
              let dueDate = task.due_date;
              if (dueDate) {
                if (dueDate.toDate) {
                  dueDate = dueDate.toDate();
                } else if (!(dueDate instanceof Date)) {
                  dueDate = new Date(dueDate);
                }
                if (isNaN(dueDate)) {
                  dueDate = null;
                }
              } else {
                dueDate = null;
              }

              return {
                ...task,
                position:
                  typeof task.position === 'number' ? task.position : index,
                due_date: dueDate,
                completed: task.completed ?? false,
                hearted: task.hearted ?? false,
                is_rendered_as_separator:
                  task.is_rendered_as_separator ?? false,
                liked: task.liked ?? false,
                done: task.done ?? false,
                custom_properties: task.custom_properties ?? {},
                parent_id: task.parent_id ?? null,
              };
            });

            // Sort tasks based on 'position' field
            initializedTasks.sort((a, b) => a.position - b.position);
            console.log(`[TaskTreeGrid] Initialized and sorted tasks:`, initializedTasks);

            // Update state with fetched tasks
            setTasks(initializedTasks);
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching tasks: ', error);
            setLoading(false);
          }
        );

        return () => {
          console.log('[TaskTreeGrid] Cleaning up tasks and unsubscribing.');
          setTasks([]);
          unsubscribe();
        };
      } else {
        console.log('[TaskTreeGrid] selectedProjectId or Firestore or currentUser is missing. Clearing tasks.');
        setTasks([]);
      }
    };

    const unsubscribe = fetchTasks();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [firestore, selectedProjectId, currentUser, groupId]);

  /**
   * Checkbox Template: Renders a checkbox for boolean fields
   * 
   * @param {Object} props - The current row data and column information
   * @returns JSX Element
   */
  const booleanTemplate = (props) => {
    const field = props.column.field; // Get the field name
    return (
      <CheckBoxComponent
        checked={props[field]} // Access the field's value
        change={(e) => {
          const updatedRecord = { ...props };
          updatedRecord[field] = e.checked; // Update the checked value
          handleBooleanCellEdit(updatedRecord, field); // Handle updating the value in Firestore
        }}
        // Prevent the click event from propagating to the cell, which would trigger edit mode
        // This ensures that clicking the checkbox only toggles it without entering edit mode
        // This is crucial to prevent the checkbox from turning into text
        // by stopping the event from reaching the load function's handler
        onClick={(e) => {
          e.stopPropagation();
          console.log(`[TaskTreeGrid] Checkbox clicked for field: ${props.column.field}`);
        }}
      />
    );
  };

  /**
   * Handles updating boolean fields in Firestore when a checkbox is toggled
   * 
   * @param {Object} record - The task record being updated
   * @param {string} field - The boolean field to update
   */
  const handleBooleanCellEdit = async (record, field) => {
    const taskId = record.id;

    if (!taskId) {
      console.warn('[TaskTreeGrid] Task ID is missing. Cannot update boolean field.');
      return;
    }

    const taskDocRef = doc(firestore, 'users', currentUser.uid, 'tasks', taskId);
    const updateObject = { [field]: record[field] };

    console.log(`[TaskTreeGrid] Updating task '${taskId}' with`, updateObject);

    try {
      await updateDoc(taskDocRef, updateObject);
      console.log(`Task '${taskId}' updated successfully in Firestore.`);
    } catch (error) {
      console.error('Error updating task in Firestore:', error);
    }
  };

  /**
   * load Function: Enables single-click editing for non-boolean fields
   * 
   * This function is passed to the TreeGridComponent's 'load' event.
   * It attaches a 'mousedown' event listener to handle cell clicks.
   */
  const load = () => {
    console.log(`[TaskTreeGrid] load function called for gridId: ${gridId}`);
    const treegridObj = document.getElementById(gridId)?.ej2_instances[0];
    if (treegridObj != null) {
      console.log(`[TaskTreeGrid] Found TreeGrid instance for gridId: ${gridId}`);

      const handleMouseDown = (e) => {
        // Check if the clicked element is within a cell
        if (
          e.target.closest('td') !== null &&
          e.target.closest('td').classList.contains('e-rowcell') &&
          !e.target.classList.contains('e-treegridexpand') &&
          !e.target.classList.contains('e-treegridcollapse') &&
          e.target.closest('td').getAttribute('aria-colIndex') !== null
        ) {
          let target = e.target.closest('td');
          let rowElement = target.parentElement;
          let rowIndex = parseInt(rowElement.getAttribute('data-rowindex'), 10);
          let colIndex = parseInt(target.getAttribute('aria-colindex'), 10);
          let field = treegridObj.getColumns()[colIndex - 1].field;

          console.log(`[TaskTreeGrid] Cell clicked: rowIndex=${rowIndex}, colIndex=${colIndex}, field=${field}`);

          // If the field is a boolean field, do not trigger edit mode
          if (booleanFields.includes(field)) {
            console.log(`[TaskTreeGrid] Field '${field}' is a boolean field. Skipping edit mode.`);
            return;
          }

          // If already in edit mode, save the current edit
          if (
            treegridObj.grid.isEdit &&
            !target.classList.contains('e-editedbatchcell') &&
            !document.getElementsByClassName('e-addedrow').length
          ) {
            console.log('[TaskTreeGrid] Saving current edit before starting a new one.');
            treegridObj.grid.saveCell();
          }

          // If not in edit mode, start editing the clicked cell
          if (!treegridObj.grid.isEdit) {
            console.log(`[TaskTreeGrid] Initiating edit mode for field '${field}' at rowIndex=${rowIndex}.`);
            setTimeout(function () {
              treegridObj.editCell(rowIndex, field);
            }, 0);
          }
        }
      };

      // Attach the mousedown event listener
      treegridObj.element.addEventListener('mousedown', handleMouseDown);
      console.log('[TaskTreeGrid] Event listener for mousedown added.');

      // Cleanup event listener on unmount
      return () => {
        console.log('[TaskTreeGrid] Cleaning up mousedown event listener.');
        treegridObj.element.removeEventListener('mousedown', handleMouseDown);
      };
    } else {
      console.error(`[TaskTreeGrid] No TreeGrid instance found for gridId: ${gridId}`);
    }
  };

  /**
   * Renders the columns for the TreeGrid based on the provided allColumns prop
   * 
   * @returns Array of <ColumnDirective> elements
   */
  const renderColumns = () => {
    return allColumns.map((column) => {
      const {
        field,
        headerText,
        type,
        visible,
        isFrozen,
        showInColumnChooser,
        allowEditing,
      } = column;

      if (!field) {
        console.warn('[TaskTreeGrid] Column without a field detected. Skipping:', column);
        return null;
      }

      let editType = 'stringedit';
      let columnType = undefined;
      let format = undefined;
      let template = undefined;

      // Determine the edit type and template based on the column type
      if (
        booleanFields.includes(field) ||
        type === 'Checkbox'
      ) {
        template = booleanTemplate;
      } else if (type === 'Date' || field === 'due_date' || type === 'DateRange') {
        editType = 'datepickeredit';
        columnType = 'date';
        format = { type: 'date', format: 'dd-MM-yyyy' };
      }

      const columnProps = {
        field,
        headerText,
        width: field === 'name' ? '210' : '150',
        textAlign: 'Left',
        isFrozen: isFrozen,
        editType: editType,
        visible: visible,
        allowEditing: allowEditing !== false,
        showInColumnChooser: showInColumnChooser !== false,
        type: columnType,
        format: format,
        template: template, // Using the template to enable checkbox toggle on a single click
      };

      // Hide the 'id' column as it's the primary key
      if (field === 'id') {
        columnProps.isPrimaryKey = true;
        columnProps.visible = false;
      }

      console.log(`[TaskTreeGrid] Rendering column: ${field}`);

      return <ColumnDirective key={field} {...columnProps} />;
    });
  };

  /**
   * Handles action completion events from the TreeGrid (e.g., saving edits)
   * 
   * @param {Object} args - The action completion event arguments
   */
  const handleActionComplete = async (args) => {
    if (args.type === 'save') {
      const updatedRecord = args.data;
      const editedField = args.column ? args.column.field : undefined;
      let newValue = editedField ? updatedRecord[editedField] : undefined;
      const taskId = updatedRecord.id;

      console.log(`[TaskTreeGrid] ActionComplete - save: taskId=${taskId}, field=${editedField}, newValue=${newValue}`);

      if (!taskId || !editedField) {
        console.warn('[TaskTreeGrid] Task ID or edited field is missing. Skipping update.');
        return;
      }

      // Handle date fields by converting to Firestore Timestamp
      if (editedField === 'due_date' && newValue) {
        if (newValue instanceof Date) {
          newValue = Timestamp.fromDate(newValue);
        } else {
          newValue = null;
        }
      }

      const taskDocRef = doc(firestore, 'users', currentUser.uid, 'tasks', taskId);
      const updateObject = { [editedField]: newValue };

      console.log(`[TaskTreeGrid] Updating task '${taskId}' with`, updateObject);

      try {
        await updateDoc(taskDocRef, updateObject);
        console.log(`Task '${taskId}' updated successfully in Firestore.`);
      } catch (error) {
        console.error('Error updating task in Firestore:', error);
      }
    }
  };

  /**
   * Handles the start of a row drag event for reordering tasks
   * 
   * @param {Object} args - The row drag start event arguments
   */
  const handleRowDragStart = (args) => {
    const treegridObj = document.getElementById(gridId)?.ej2_instances[0];
    if (!treegridObj) {
      console.error(`[TaskTreeGrid] handleRowDragStart - No TreeGrid instance found for gridId: ${gridId}`);
      return;
    }

    const currentData = treegridObj.getCurrentViewRecords();
    const oldPositions = {};
    currentData.forEach((task) => {
      oldPositions[task.id] = task.position;
    });
    draggedOldPositionsRef.current = oldPositions;

    console.log(`[TaskTreeGrid] RowDragStart - Storing old positions:`, oldPositions);
  };

  /**
   * Handles the drop event of a row drag for reordering tasks
   * 
   * @param {Object} args - The row drop event arguments
   */
  const handleRowDrop = async (args) => {
    const treegridObj = document.getElementById(gridId)?.ej2_instances[0];
    if (!treegridObj) {
      console.error(`[TaskTreeGrid] handleRowDrop - No TreeGrid instance found for gridId: ${gridId}`);
      return;
    }

    const updatedData = treegridObj.getCurrentViewRecords();
    const batch = writeBatch(firestore);
    let changesDetected = false;

    updatedData.forEach((task, index) => {
      const newPosition = index;
      const oldPosition = draggedOldPositionsRef.current[task.id];

      if (oldPosition !== newPosition) {
        const taskDocRef = doc(
          firestore,
          'users',
          currentUser.uid,
          'tasks',
          task.id
        );
        batch.update(taskDocRef, { position: newPosition });
        changesDetected = true;
        task.position = newPosition;
      }
    });

    if (!changesDetected) {
      console.log('[TaskTreeGrid] No changes detected in task positions.');
      return;
    }

    console.log('[TaskTreeGrid] Committing batch update for reordered tasks.');

    try {
      isUpdatingRef.current = true;
      await batch.commit();
      setTasks([...updatedData]);
      console.log('[TaskTreeGrid] Batch update committed successfully.');
    } catch (error) {
      console.error('Error updating task order:', error);
    } finally {
      isUpdatingRef.current = false;
    }
  };

  return (
    <div className="control-pane" role="region" aria-label="Tree Grid Control">
      <div className="control-section" style={{ overflowX: 'auto' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {/* TreeGridComponent: Displays the tasks in a tree grid */}
            <TreeGridComponent
              dataSource={tasks}
              treeColumnIndex={1} // Index of the tree column (e.g., 'name' column)
              idMapping="id" // Primary key field
              parentIdMapping="parent_id" // Parent ID field for tree structure
              height="auto"
              width="auto"
              allowReordering={true} // Enable row reordering via drag-and-drop
              allowFiltering={true} // Enable filtering
              allowSorting={true} // Enable sorting
              allowRowDragAndDrop={true} // Enable row drag-and-drop
              selectionSettings={{
                cellSelectionMode: 'Box',
                mode: 'Cell',
                type: 'Multiple',
              }}
              editSettings={{
                allowAdding: false,
                allowDeleting: false,
                allowEditing: true,
                mode: 'Cell',
              }}
              filterSettings={{ type: 'Menu' }}
              sortSettings={{
                columns: [{ field: 'position', direction: 'Ascending' }],
              }}
              key={`${selectedProjectId}${groupId ? `_${groupId}` : ''}`} // Unique key based on projectId and groupId
              showColumnChooser={true} // Enable column chooser
              toolbar={toolbarOptions} // Toolbar options
              rowDragStart={handleRowDragStart} // Handle row drag start
              rowDrop={handleRowDrop} // Handle row drop
              load={load} // Pass the load function to handle single-click editing
              actionComplete={handleActionComplete} // Handle action completion events
              contextMenuItems={contextMenuItems} // Define context menu items
              contextMenuClick={async (args) => {
                if (args.item.id === 'deleteTask') {
                  const taskData = args.rowInfo.rowData;
                  const taskId = taskData.id;
                  console.log(`[TaskTreeGrid] Deleting task with ID: ${taskId}`);
                  if (window.confirm('Are you sure you want to delete this task?')) {
                    try {
                      const taskDocRef = doc(
                        firestore,
                        'users',
                        currentUser.uid,
                        'tasks',
                        taskId
                      );
                      await deleteDoc(taskDocRef);
                      console.log(`Task '${taskId}' deleted successfully.`);
                    } catch (error) {
                      console.error('Error deleting task:', error);
                    }
                  }
                }
              }}
              enableHover={true} // Enable hover effects
              id={gridId} // Assign unique gridId
            >
              <ColumnsDirective>
                {/* Hide the 'id' column as it's the primary key */}
                <ColumnDirective
                  field="id"
                  headerText="ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                {/* Hide the 'position' column as it's used for sorting */}
                <ColumnDirective
                  field="position"
                  headerText="Position"
                  visible={false}
                />
                {/* Render dynamic columns based on allColumns prop */}
                {renderColumns()}
              </ColumnsDirective>
              <Inject
                services={[
                  Filter,
                  Sort,
                  Reorder,
                  Edit,
                  RowDD,
                  Selection,
                  Toolbar,
                  ColumnChooser,
                  Freeze,
                  ContextMenu,
                ]}
              />
            </TreeGridComponent>

            {/* Add Task Button: Opens the AddTask modal */}
            <div className="task-grid-toolbar" style={{ marginBottom: '0px' }}>
              <button
                className="add-task-button"
                onClick={() => {
                  console.log('[TaskTreeGrid] Add Task button clicked.');
                  setIsAddingTask(true);
                }}
              >
                Add Task
              </button>
            </div>

            {/* AddTask Modal: Allows adding a new task */}
            {isAddingTask && (
              <AddTask
                projectId={selectedProjectId}
                groupId={groupId} // Pass groupId to associate the task with a group if applicable
                gridTasks={tasks}
                onTaskAdded={(newTasks) => {
                  console.log('[TaskTreeGrid] Task added. Updating tasks state.');
                  setTasks(newTasks);
                  setIsAddingTask(false);
                }}
                onClose={() => {
                  console.log('[TaskTreeGrid] Add Task modal closed.');
                  setIsAddingTask(false);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

/**
 * GroupSection Component
 * 
 * Renders a collapsible section for a specific group, containing its own TaskTreeGrid.
 * 
 * Props:
 * - group: The group object containing group details.
 * - selectedProjectId: ID of the selected project to filter tasks.
 * - allColumns: Array of column configurations for the TreeGrid.
 */
const GroupSection = ({ group, selectedProjectId, allColumns }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  console.log(`[GroupSection] Rendering GroupSection for groupId: ${group.id}`);

  return (
    <div className="group-section">
      {/* Group Header: Toggles collapse state on click */}
      <div
        className="group-header"
        onClick={() => {
          console.log(`[GroupSection] Toggling collapse state for groupId: ${group.id}`);
          setIsCollapsed(!isCollapsed);
        }}
      >
        {/* Arrow Indicator: Points down when expanded, right when collapsed */}
        <span className={`arrow ${isCollapsed ? 'right' : 'down'}`}></span>
        <h3>{group.name}</h3>
      </div>

      {/* Group Tasks: Only visible when not collapsed */}
      {!isCollapsed && (
        <div className="group-tasks">
          <TaskTreeGrid
            selectedProjectId={selectedProjectId}
            allColumns={allColumns} // Ensure consistent columns
            groupId={group.id} // Filter tasks by this groupId
            gridId={`treegrid-${group.id}`} // Unique gridId per group
          />
        </div>
      )}
    </div>
  );
};

export default TaskTreeGrid;
