import React, { useEffect } from 'react';

const ColumnManager = ({ onColumnsChange }) => {
  const alwaysVisibleColumns = [
    { field: 'name', headerText: 'Name', type: 'Text', isFrozen: true, visible: true, showInColumnChooser: false },
    { field: 'assignee', headerText: 'Assignee', type: 'Text', visible: true },
    { field: 'due_date', headerText: 'Due date', type: 'DateRange', visible: true },
    { field: 'completed', headerText: 'Completed', type: 'Checkbox', visible: true },
  ];

  useEffect(() => {
    // Initialize all possible columns with all properties from Task.js
    const taskProperties = [
      { field: 'gid', headerText: 'GID', type: 'Text', visible: false },
      { field: 'approval_status', headerText: 'Approval Status', type: 'Text', visible: false },
      { field: 'assignee_status', headerText: 'Assignee Status', type: 'Text', visible: false },
      { field: 'completed_at', headerText: 'Completed At', type: 'Date', visible: false },
      { field: 'completed_by', headerText: 'Completed By', type: 'Text', visible: false },
      { field: 'created_at', headerText: 'Created At', type: 'Date', visible: false },
      { field: 'created_by', headerText: 'Created By', type: 'Text', visible: false },
      { field: 'custom_fields', headerText: 'Custom Fields', type: 'Text', visible: false },
      { field: 'dependencies', headerText: 'Dependencies', type: 'Text', visible: false },
      { field: 'dependents', headerText: 'Dependents', type: 'Text', visible: false },
      { field: 'due_at', headerText: 'Due At', type: 'Date', visible: false },
      { field: 'due_date_start', headerText: 'Due Date Start', type: 'Date', visible: false },
      { field: 'due_date_end', headerText: 'Due Date End', type: 'Date', visible: false },
      { field: 'external', headerText: 'External', type: 'Text', visible: false },
      { field: 'followers', headerText: 'Followers', type: 'Text', visible: false },
      { field: 'html_notes', headerText: 'HTML Notes', type: 'Text', visible: false },
      { field: 'hearted', headerText: 'Hearted', type: 'Checkbox', visible: false },
      { field: 'hearts', headerText: 'Hearts', type: 'Number', visible: false },
      { field: 'is_rendered_as_separator', headerText: 'Rendered as Separator', type: 'Checkbox', visible: false },
      { field: 'liked', headerText: 'Liked', type: 'Checkbox', visible: false },
      { field: 'likes', headerText: 'Likes', type: 'Number', visible: false },
      { field: 'memberships', headerText: 'Memberships', type: 'Text', visible: false },
      { field: 'modified_at', headerText: 'Modified At', type: 'Date', visible: false },
      { field: 'notes', headerText: 'Notes', type: 'Text', visible: false },
      { field: 'num_hearts', headerText: 'Number of Hearts', type: 'Number', visible: false },
      { field: 'num_likes', headerText: 'Number of Likes', type: 'Number', visible: false },
      { field: 'parent', headerText: 'Parent', type: 'Text', visible: false },
      { field: 'permalink_url', headerText: 'Permalink URL', type: 'Text', visible: false },
      { field: 'projects', headerText: 'Projects', type: 'Text', visible: false },
      { field: 'resource_subtype', headerText: 'Resource Subtype', type: 'Text', visible: false },
      { field: 'resource_type', headerText: 'Resource Type', type: 'Text', visible: false },
      { field: 'start_on', headerText: 'Start On', type: 'Date', visible: false },
      { field: 'tags', headerText: 'Tags', type: 'Text', visible: false },
      { field: 'workspace', headerText: 'Workspace', type: 'Text', visible: false },
      { field: 'done', headerText: 'Done', type: 'Checkbox', visible: false },
      { field: 'project_id', headerText: 'Project ID', type: 'Text', visible: false },
      { field: 'group_id', headerText: 'Group ID', type: 'Text', visible: false },
      { field: 'assigned_to', headerText: 'Assigned To', type: 'Text', visible: false },
      { field: 'position', headerText: 'Position', type: 'Number', visible: false },
      // Add any additional properties from Task.js if missing
    ];

    const allColumns = [...alwaysVisibleColumns, ...taskProperties];

    if (onColumnsChange) {
      onColumnsChange(allColumns);
    }
  }, []);

  return null;
};

export default ColumnManager;
