// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  // Removed setPersistence and browserLocalPersistence
} from 'firebase/auth';
import { auth } from '../services/firebase'; // Ensure consistent auth instance

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Setting up onAuthStateChanged listener');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is signed in:', user);
      } else {
        console.log('No user is signed in');
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return () => {
      console.log('Cleaning up onAuthStateChanged listener');
      unsubscribe();
    };
  }, []);

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      // Remove or modify setPersistence if necessary
      // console.log('Setting persistence to browserSessionPersistence');
      // await setPersistence(auth, browserSessionPersistence);
      const result = await signInWithPopup(auth, provider);
      console.log('User signed in:', result.user);
      // No need to manually set currentUser; onAuthStateChanged will handle it
      return result.user;
    } catch (error) {
      console.error('Error signing in with Google: ', error);
    }
  };

  const logout = async () => {
    try {
      console.log('Attempting to log out...');
      await signOut(auth); // Sign out from Firebase
      console.log('Successfully logged out');
      // No need to manually set currentUser; onAuthStateChanged will handle it
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, signInWithGoogle, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
