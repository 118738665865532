// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'; // Import reportWebVitals correctly
import { registerLicense } from '@syncfusion/ej2-base';

// Register the Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ccXRURWlYWUJ2VkM=');

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container); // Create a root for React 18
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element.');
}

// Make sure reportWebVitals is imported and used correctly
reportWebVitals();

