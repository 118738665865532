// src/models/Task.js

class Task {
  constructor({
    gid = '', // Globally unique identifier for the task
    approval_status = null, // The approval status of the task, if applicable
    assignee = null, // The user assigned to the task
    assignee_status = 'inbox', // The status of the task as it relates to the assignee
    completed = false, // Whether the task is completed
    completed_at = null, // The timestamp when the task was completed
    completed_by = null, // The user who completed the task
    created_at = new Date(), // The timestamp when the task was created
    created_by = null, // The user who created the task
    custom_fields = [], // Custom fields associated with the task
    dependencies = [], // The tasks that this task is dependent on
    dependents = [], // The tasks that depend on this task
    due_at = null, // The date and time when the task is due, including time
    due_on = null, // The date when the task is due
    external = null, // External data associated with the task
    followers = [], // The users following the task
    group_id = [], // the group the task is associated with
    html_notes = '', // The HTML formatted notes of the task
    hearted = false, // Whether the task is hearted by the current user
    hearts = [], // The users who have hearted the task
    is_rendered_as_separator = false, // Whether the task is rendered as a separator
    liked = false, // Whether the task is liked by the current user
    likes = [], // The users who have liked the task
    memberships = [], // The memberships that this task is associated with
    modified_at = new Date(), // The timestamp when the task was last modified
    name = '', // The name of the task
    notes = '', // The plain-text notes of the task
    num_hearts = 0, // The number of hearts the task has
    num_likes = 0, // The number of likes the task has
    parent = null, // The parent task, if this is a subtask
    permalink_url = '', // The permalink URL of the task
    projects = [], // The projects the task is associated with
    resource_subtype = '', // The subtype of the resource
    resource_type = 'task', // The type of the resource
    start_on = null, // The date when the task should start
    tags = [], // The tags associated with the task
    workspace = null, // The workspace the task is associated with
    done = false, // Whether the task is marked as done
    project_id = '', // The ID of the project the task is associated with
    assigned_to = '', // The ID of the user the task is assigned to
    position = 0, // The position of the task in the list
    custom_properties = {}, // Custom properties added by the user
  } = {}) {
    this.gid = gid;
    this.approval_status = approval_status;
    this.assignee = assignee;
    this.assignee_status = assignee_status;
    this.completed = completed;
    this.completed_at = completed_at;
    this.completed_by = completed_by;
    this.created_at = created_at;
    this.created_by = created_by;
    this.custom_fields = custom_fields;
    this.dependencies = dependencies;
    this.dependents = dependents;
    this.due_at = due_at;
    this.due_on = due_on;
    this.external = external;
    this.followers = followers;
    this.group_id = group_id;
    this.html_notes = html_notes;
    this.hearted = hearted;
    this.hearts = hearts;
    this.is_rendered_as_separator = is_rendered_as_separator;
    this.liked = liked;
    this.likes = likes;
    this.memberships = memberships;
    this.modified_at = modified_at;
    this.name = name;
    this.notes = notes;
    this.num_hearts = num_hearts;
    this.num_likes = num_likes;
    this.parent = parent;
    this.permalink_url = permalink_url;
    this.projects = projects;
    this.resource_subtype = resource_subtype;
    this.resource_type = resource_type;
    this.start_on = start_on;
    this.tags = tags;
    this.workspace = workspace;
    this.done = done;
    this.project_id = project_id;
    this.assigned_to = assigned_to;
    this.position = position;
    this.custom_properties = custom_properties;
  }
}

export default Task;
