// src/components/Auth/GoogleSignIn.js
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc'; // Google icon
import '../../styles/SocialSignInButton.css'; // CSS for social sign-in buttons

const GoogleSignIn = () => {
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const user = await signInWithGoogle();
    if (user) {
      navigate('/');
    }
  };

  return (
    <button className="social-sign-in-button" onClick={handleLogin}>
      <FcGoogle className="icon" />
      Sign in with Google
    </button>
  );
};

export default GoogleSignIn;
