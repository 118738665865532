// src/pages/SignInPage.js
import React from 'react';
import MainLayout from '../components/Layout/MainLayout';
import '../styles/SignInPage.css';

const SignInPage = () => {
  return (
    <MainLayout>
      <div className="marketing-page">
        <section className="hero-section">
          <h1>Revolutionize Your Task Management</h1>
          <p>Streamline your workflow, collaborate seamlessly, and achieve more with our intuitive platform.</p>
          <button className="primary-button">Get Started Free</button>
        </section>
        <section className="features-section">
          <div className="feature">
            <h2>Organize</h2>
            <p>Keep all your tasks and projects organized in one place with our user-friendly interface.</p>
          </div>
          <div className="feature">
            <h2>Collaborate</h2>
            <p>Work together with your team in real-time, assign tasks, and track progress effortlessly.</p>
          </div>
          <div className="feature">
            <h2>Achieve</h2>
            <p>Set goals, monitor performance, and achieve success with insightful analytics.</p>
          </div>
        </section>
        <section className="call-to-action-section">
          <h2>Ready to Transform Your Productivity?</h2>
          <button className="primary-button">Join Now</button>
        </section>
      </div>
    </MainLayout>
  );
};

export default SignInPage;
