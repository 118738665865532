import React, { useEffect, useState } from 'react';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Sort, Filter, Reorder, Edit, Freeze, RowDD, Selection } from '@syncfusion/ej2-react-treegrid';
import { useAuth } from '../contexts/AuthContext';
import { FirestoreContext } from '../contexts/FirestoreContext';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import '../styles/App.css';

const MyTasksPage = () => {
  const { currentUser } = useAuth();
  const { firestore } = React.useContext(FirestoreContext);
  const [tasks, setTasks] = useState({ today: [], week: [], later: [], recentlyAssigned: [] });
  const [loading, setLoading] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    recentlyAssigned: true,
    today: true,
    week: true,
    later: true,
  });

  useEffect(() => {
    if (firestore && currentUser) {
      setLoading(true);
      const q = query(
        collection(firestore, 'users', currentUser.uid, 'tasks'),
        orderBy('due_date')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tasksData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          tasksData.push({ id: doc.id, ...data });
        });

        const categorizedTasks = categorizeTasks(tasksData);
        setTasks(categorizedTasks);
        setLoading(false);
      }, (error) => {
        console.error("Error fetching tasks: ", error);
        setLoading(false);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [firestore, currentUser]);

  const categorizeTasks = (tasks) => {
    const now = new Date();
    const today = [];
    const week = [];
    const later = [];
    const recentlyAssigned = [];

    tasks.forEach(task => {
      const dueDate = task.due_date ? task.due_date : null;

      if (dueDate) {
        const timeDifference = dueDate - now;
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        if (daysDifference <= 0) {
          today.push(task);
        } else if (daysDifference <= 7) {
          week.push(task);
        } else {
          later.push(task);
        }
      } else {
        recentlyAssigned.push(task);
      }
    });

    return { today, week, later, recentlyAssigned };
  };

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const renderTaskTable = (tasks, header, section) => (
    <div className="task-section">
      <h3 onClick={() => toggleSection(section)} className="task-header">
        {header} <span className="arrow">{expandedSections[section] ? '▼' : '▶'}</span>
      </h3>
      {expandedSections[section] && (
        <TreeGridComponent
          dataSource={tasks}
          childMapping="subtasks"
          height="400"
          allowReordering={true}
          allowFiltering={true}
          allowSorting={true}
          allowRowDragAndDrop={true}
          selectionSettings={{ type: "Multiple" }}
          editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: true }}
          filterSettings={{ type: "Menu", hierarchyMode: "Parent" }}
        >
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" width="210" textAlign="Left" />
            <ColumnDirective field="assignee" headerText="Assignee" width="150" textAlign="Left" />
            <ColumnDirective field="due_date" headerText="Due date" width="150" textAlign="Left" />
            <ColumnDirective field="completed" headerText="Completed" width="150" textAlign="Left" />
          </ColumnsDirective>
          <Inject services={[Filter, Sort, Reorder, Edit, Page, Freeze, RowDD, Selection]} />
        </TreeGridComponent>
      )}
    </div>
  );

  return (
    <div style={styles.pageContainer}>
      <div style={styles.container}>
        <h2>My Tasks</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {renderTaskTable(tasks.recentlyAssigned, 'Recently assigned', 'recentlyAssigned')}
            {renderTaskTable(tasks.today, 'Do today', 'today')}
            {renderTaskTable(tasks.week, 'Do next week', 'week')}
            {renderTaskTable(tasks.later, 'Do later', 'later')}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: "'Proxima Nova', Arial, sans-serif",
    height: '100vh',
    overflowY: 'auto',
    paddingLeft: '200px',
    paddingRight: '200px',
  },
  container: {
    width: '100%',
    padding: '20px',
  },
  taskSection: {
    marginBottom: '20px',
  },
  taskHeader: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#f5f5f5',
    padding: '10px',
    borderRadius: '5px',
  },
  arrow: {
    marginLeft: '10px',
  },
};

export default MyTasksPage;
