// src/services/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBKvVLsZ5Ej4isPwROJUyPBloR-exDatas",
  authDomain: "bblockstudios-a9eec.firebaseapp.com",
  projectId: "bblockstudios-a9eec",
  storageBucket: "bblockstudios-a9eec.appspot.com",
  messagingSenderId: "323061696674",
  appId: "1:323061696674:web:5dc527e265aba8df22affb",
  measurementId: "G-541HQWJJTN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

export { auth, provider, db };