// src/components/Tasks/AddTask.js

import React, { useState } from 'react';
import { addDocument } from '../../services/firestoreService';
import useAuth from '../../hooks/useAuth';
import Task from '../../models/Task';
import '../../styles/AddTaskModal.css';

/**
 * AddTask Component
 * 
 * Provides a form to add a new task. Assigns the task to a group if groupId is provided.
 * If no groupId is provided, assigns the task to the 'Ungrouped' group by setting group_id to null.
 * 
 * Props:
 * - projectId: ID of the project the task belongs to.
 * - groupId: (Optional) ID of the group the task belongs to. If null, task is ungrouped.
 * - gridTasks: Current list of tasks in the grid (used to determine the next position).
 * - onTaskAdded: Callback function to update the grid after adding a task.
 * - onClose: Callback function to close the AddTask modal.
 */
const AddTask = ({ projectId, groupId = null, gridTasks, onTaskAdded, onClose }) => {
  const { currentUser } = useAuth();
  const [newTask, setNewTask] = useState(new Task());

  // Ensure that each input field has a default value to prevent null or undefined values
  const handleAddTask = async () => {
    if (!newTask.name.trim()) {
      alert('Task name is required.');
      return;
    }

    const nextPosition = gridTasks.length;

    const taskData = {
      ...newTask,
      project_id: projectId,
      group_id: groupId,
      created_by: currentUser.uid,
      created_at: new Date(),
      updated_at: new Date(),
      position: nextPosition,
    };

    try {
      const taskId = await addDocument('tasks', taskData);
      console.log(`[AddTask] Task '${taskId}' added successfully.`);
      
      setNewTask(new Task());
      onTaskAdded(prevTasks => [...prevTasks, { id: taskId, ...taskData }]);
      onClose();
    } catch (error) {
      console.error('Error adding task:', error);
      alert('Failed to add task. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setNewTask((prevTask) => ({
      ...prevTask,
      [name]:
        type === 'checkbox'
          ? checked
          : ['tags', 'followers', 'hearts', 'likes', 'projects', 'dependencies', 'dependents'].includes(name)
          ? value.split(',').map((item) => item.trim())
          : value,
    }));
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="sign-in-modal add-task-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Add new task</h2>
        <div className="modal-form modal-form-columns">
          <div className="form-scrollable">
            <div className="form-columns">
              <div className="form-column">
                {/* First column of fields */}
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={newTask.name || ""}
                    onChange={handleInputChange}
                    required
                    placeholder=" "
                  />
                  <label>Task Name</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <textarea
                    name="notes"
                    value={newTask.notes || ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  ></textarea>
                  <label>Notes</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="assignee"
                    value={newTask.assignee || ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Assignee</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="assignee_status"
                    value={newTask.assignee_status || ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Assignee Status</label>
                  <div className="bar"></div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="completed"
                    checked={newTask.completed || false}
                    onChange={handleInputChange}
                  />
                  <label>Completed</label>
                </div>
                <div className="form-group">
                  <input
                    type="datetime-local"
                    name="due_date"
                    value={newTask.due_date || ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Due Date</label>
                  <div className="bar"></div>
                </div>
              </div>
              <div className="form-column">
                {/* Second column of fields */}
                <div className="form-group">
                  <input
                    type="text"
                    name="tags"
                    value={newTask.tags ? newTask.tags.join(', ') : ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Tags (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <textarea
                    name="html_notes"
                    value={newTask.html_notes || ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  ></textarea>
                  <label>HTML Notes</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="followers"
                    value={newTask.followers ? newTask.followers.join(', ') : ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Followers (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="hearted"
                    checked={newTask.hearted || false}
                    onChange={handleInputChange}
                  />
                  <label>Hearted</label>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="is_rendered_as_separator"
                    checked={newTask.is_rendered_as_separator || false}
                    onChange={handleInputChange}
                  />
                  <label>Rendered as Separator</label>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="liked"
                    checked={newTask.liked || false}
                    onChange={handleInputChange}
                  />
                  <label>Liked</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="hearts"
                    value={newTask.hearts ? newTask.hearts.join(', ') : ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Hearts (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="likes"
                    value={newTask.likes ? newTask.likes.join(', ') : ""}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Likes (comma-separated)</label>
                  <div className="bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Buttons */}
        <div className="modal-buttons">
          <button className="submit-button" onClick={onClose}>
            Cancel
          </button>
          <button className="submit-button" onClick={handleAddTask}>
            Add Task
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
