// src/views/ListView.js

import React, { useState, useEffect, useRef } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import TaskTreeGrid from '../components/Tasks/TaskTreeGrid';
import '../styles/App.css';
import TaskDetails from '../components/Tasks/TaskDetails';
import ColumnManager from '../components/Tasks/ColumnManager';
import '../styles/ListView.css';
import { collection, query, where, onSnapshot, addDoc } from 'firebase/firestore';
import useAuth from '../hooks/useAuth';

/**
 * ListView Component
 * 
 * Displays a list view of tasks, organized by groups if applicable.
 * Always includes an "Ungrouped" section for tasks without a group.
 * Allows adding new groups and viewing task details.
 * 
 * Props:
 * - projectId: ID of the selected project to display tasks.
 * - projects: Array of all projects.
 */
const ListView = ({ projectId, projects }) => {
  // Access Firestore instance from context
  const { firestore } = useFirestore();

  // Access current user from authentication hook
  const { currentUser } = useAuth();

  // State to store the selected project object
  const [selectedProject, setSelectedProject] = useState(null);

  // State to store all tasks fetched from Firestore
  const [taskData, setTaskData] = useState([]);

  // State to store all groups associated with the selected project
  const [groups, setGroups] = useState([]);

  // State to manage the visibility of the AddGroup modal
  const [isAddingGroup, setIsAddingGroup] = useState(false);

  // State to store the new group name input
  const [newGroupName, setNewGroupName] = useState('');

  // State to store the currently selected task for viewing details
  const [selectedTask, setSelectedTask] = useState(null);

  // Ref to the task details section for handling click events
  const taskDetailsRef = useRef(null);

  // State to store all column configurations for the TreeGrid
  const [allColumns, setAllColumns] = useState([]);

  /**
   * Predefined Ungrouped Group
   * 
   * This group represents tasks without an associated group.
   * It is always present at the top of the groups list.
   */
  const ungroupedGroup = {
    id: null, // group_id is null for ungrouped tasks
    name: 'Ungrouped',
  };

  /**
   * useEffect Hook: Sets the selectedProject based on projectId prop
   */
  useEffect(() => {
    if (projectId) {
      const project = projects.find((proj) => proj.id === projectId);
      setSelectedProject(project);
      console.log(`[ListView] Selected project set to: ${project ? project.name : 'None'}`);
    }
  }, [projectId, projects]);

  /**
   * useEffect Hook: Fetches groups associated with the selected project from Firestore
   */
  useEffect(() => {
    const fetchGroups = () => {
      if (selectedProject && firestore && currentUser) {
        console.log(`[ListView] Fetching groups for projectId: ${selectedProject.id}`);
        const groupsCollection = collection(firestore, 'users', currentUser.uid, 'groups');
        const q = query(
          groupsCollection,
          where('project_id', '==', selectedProject.id)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const groupsData = [];
          querySnapshot.forEach((docSnap) => {
            const data = docSnap.data();
            groupsData.push({ id: docSnap.id, ...data });
          });
          console.log(`[ListView] Fetched groups:`, groupsData);
          setGroups(groupsData);
        }, (error) => {
          console.error('Error fetching groups: ', error);
        });

        return () => {
          console.log('[ListView] Cleaning up groups listener.');
          unsubscribe();
        };
      } else {
        console.log('[ListView] selectedProject, Firestore, or currentUser is missing. Clearing groups.');
        setGroups([]);
      }
    };

    const unsubscribe = fetchGroups();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [selectedProject, firestore, currentUser]);

  /**
   * useEffect Hook: Fetches tasks associated with the selected project from Firestore
   */
  useEffect(() => {
    if (selectedProject && firestore && currentUser) {
      console.log(`[ListView] Fetching tasks for projectId: ${selectedProject.id}`);
      const tasksCollection = collection(firestore, 'users', currentUser.uid, 'tasks');
      const q = query(
        tasksCollection,
        where('project_id', '==', selectedProject.id)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tasksData = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          tasksData.push({ id: docSnap.id, ...data });
        });

        console.log(`[ListView] Fetched ${tasksData.length} tasks.`);
        setTaskData(tasksData);
      }, (error) => {
        console.error('Error fetching tasks: ', error);
      });

      return () => {
        console.log('[ListView] Cleaning up tasks listener.');
        setTaskData([]);
        unsubscribe();
      };
    } else {
      console.log('[ListView] selectedProject, Firestore, or currentUser is missing. Clearing tasks.');
      setTaskData([]);
    }
  }, [firestore, selectedProject, currentUser]);

  /**
   * Handles selecting a task to view its details
   * 
   * @param {Object} task - The task object that was selected
   */
  const handleTaskSelect = (task) => {
    console.log(`[ListView] Task selected: ${task.id}`);
    setSelectedTask(task);
    if (taskDetailsRef.current) {
      taskDetailsRef.current.classList.add('show');
    }
  };

  /**
   * Handles closing the task details view
   */
  const handleTaskDetailsClose = () => {
    console.log('[ListView] Task details closed.');
    setSelectedTask(null);
    if (taskDetailsRef.current) {
      taskDetailsRef.current.classList.remove('show');
    }
  };

  /**
   * Handles updating the column configurations for the TreeGrid
   * 
   * @param {Array} columns - Array of column configuration objects
   */
  const handleColumnsChange = (columns) => {
    console.log('[ListView] Columns changed:', columns);
    setAllColumns(columns);
  };

  /**
   * Handles adding a new group to Firestore
   */
  const handleAddGroup = async () => {
    if (newGroupName.trim() === '') {
      alert('Group name cannot be empty.');
      return;
    }

    try {
      console.log(`[ListView] Adding new group: ${newGroupName.trim()}`);
      const groupsCollection = collection(firestore, 'users', currentUser.uid, 'groups');
      const newGroup = {
        name: newGroupName.trim(),
        project_id: selectedProject.id,
        created_at: new Date(),
        updated_at: new Date(),
      };
      await addDoc(groupsCollection, newGroup);
      console.log('[ListView] New group added successfully.');
      setNewGroupName('');
      setIsAddingGroup(false);
    } catch (error) {
      console.error('Error adding group: ', error);
    }
  };

  /**
   * Retrieves ungrouped tasks (tasks with group_id == null)
   * 
   * @returns {Array} Array of ungrouped task objects
   */
  const getUngroupedTasks = () => {
    return taskData.filter(task => task.group_id === null);
  };

  /**
   * Retrieves grouped tasks (tasks with a valid group_id)
   * 
   * @returns {Array} Array of grouped task objects
   */
  const getGroupedTasks = () => {
    return taskData.filter(task => task.group_id !== null);
  };

  return (
    <div className="listview">
      {selectedProject && (
        <>
          {/* Header Section: Contains the Add Group button */}
          <div className="listview-header">
            <button
              className="add-group-button" // Renamed class for clarity
              onClick={() => {
                console.log('[ListView] Add Group button clicked.');
                setIsAddingGroup(true);
              }}
            >
              Add Group
            </button>
          </div>

          {/* Add Group Modal: Appears when adding a new group */}
          {isAddingGroup && (
            <div className="modal-overlay" onClick={() => setIsAddingGroup(false)}>
              <div className="modal add-group-modal" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={() => setIsAddingGroup(false)}>&times;</button>
                <h2>Add new group</h2>
                <input
                  type="text"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  placeholder="Group Name"
                />
                <button onClick={handleAddGroup}>Add Group</button>
              </div>
            </div>
          )}

          {/* Main Content Wrapper: Contains the task grid and task details */}
          <div className="task-details-wrapper">
            {/* Task Grid Section: Contains ungrouped tasks and group sections */}
            <div className="task-grid">
              {/* Grid Header: Contains the ColumnManager */}
              <div className="grid-header">
                {/* ColumnManager Component: Manages column configurations */}
                <ColumnManager onColumnsChange={handleColumnsChange} />
              </div>

              {/* TreeGrid Container: Wraps all TaskTreeGrid components */}
              <div className="treegrid-container">
                {/* Ungrouped Tasks Section */}
                <div className="ungrouped-tasks">
                  {/* Removed the <h3> title for ungrouped tasks as per user request */}
                  <TaskTreeGrid
                    selectedProjectId={selectedProject.id}
                    allColumns={allColumns}
                    groupId={null} // group_id is null for ungrouped tasks
                    gridId="treegrid-ungrouped" // Unique gridId for ungrouped tasks
                  />
                </div>

                {/* Group Sections: Render a TaskTreeGrid for each group */}
                {groups.map((group) => (
                  <div key={group.id} className="group-section">
                    {/* Group Header: Displays the group name */}
                    <div className="group-header">
                      <h3>{group.name}</h3>
                    </div>
                    {/* Grouped Tasks TreeGrid */}
                    <TaskTreeGrid
                      selectedProjectId={selectedProject.id}
                      allColumns={allColumns}
                      groupId={group.id} // Filter tasks by this groupId
                      gridId={`treegrid-${group.id}`} // Unique gridId per group
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Task Details Section: Displays details of the selected task */}
            <div
              className="task-details"
              ref={taskDetailsRef}
              onClick={handleTaskDetailsClose}
            >
              {selectedTask && (
                <TaskDetails
                  task={selectedTask}
                  onClose={handleTaskDetailsClose}
                  projects={projects}
                  subtasks={[]} /* Assuming subtasks are managed elsewhere */
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ListView;
