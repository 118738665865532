// src/components/Auth/SignInForm.js
import React, { useState } from 'react';
import '../../styles/SignInForm.css';

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement sign-in with email and password here
    alert('Email and password sign-in is not implemented yet.');
  };

  return (
    <form className="sign-in-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder=" "
        />
        <label htmlFor="email">Email or Username</label>
        <div className="bar"></div>
      </div>

      <div className="form-group">
        <input
          type="password"
          id="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder=" "
        />
        <label htmlFor="password">Password</label>
        <div className="bar"></div>
      </div>

      <button type="submit" className="submit-button">
        Sign In
      </button>
    </form>
  );
};

export default SignInForm;
